import { Company } from 'src/app/services/bdoservice/companies/models/company.model';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AccountValidators, ClientSecretsValidators, patternIfFilledValidator } from 'src/app/services/bdoservice/constants';
import { CompanyService } from 'src/app/services/bdoservice/companies/company.service';
import { CreateCompanyRequest } from 'src/app/services/bdoservice/companies/models/create-company-request.model';
import { Checkbox, CheckboxChangeEvent } from 'primeng/checkbox';
import { UpdateCompanyRequest } from 'src/app/services/bdoservice/companies/models/update-company-request.model';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
})
export class CreateCompanyDialog implements OnInit {

  form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(AccountValidators.MIN_CREDENTIALS_NAME_LENGTH)]),
    nameFromBdo: new FormControl(false, []),
    clientId: new FormControl(null, [patternIfFilledValidator(ClientSecretsValidators.CLIENT_ID_PATTERN)]),
    clientSecret: new FormControl(null, [patternIfFilledValidator(ClientSecretsValidators.CLIENT_SECRET_PATTERN)]),
    nip: new FormControl(null, [patternIfFilledValidator(AccountValidators.NIP_PATTERN), Validators.required]),
  });

  formError: string = '';
  loading: boolean = false;

  constructor(
    public readonly ref: DynamicDialogRef,
    public readonly config: DynamicDialogConfig<CreateCompanyDialogData>,
    public readonly companyService: CompanyService,
  ) { }

  ngOnInit() {
    if (this.config.data.mode === 'edit') {
      this.form.controls.name.setValue(this.config.data.company.name);
      this.form.controls.clientId.removeValidators([Validators.required, Validators.minLength(AccountValidators.MIN_CREDENTIALS_NAME_LENGTH)]);
      this.form.controls.clientSecret.removeValidators([Validators.required, Validators.minLength(AccountValidators.MIN_CREDENTIALS_NAME_LENGTH)]);
      this.form.controls.clientId.disable();
      this.form.controls.clientSecret.disable();
      this.form.controls.nip.disable();
      this.form.controls.nip.setValidators([]);
    }
  }

  onNoClick() {
    this.ref.close();
  }

  saveForm() {
    this.loading = true;
    if (this.config.data.mode === 'create') {
      const request = new CreateCompanyRequest(
        this.form.controls.name.value,
        this.form.controls.nameFromBdo.value,
        this.form.controls.nip.value,
        null,
        this.form.controls.clientId.value,
        this.form.controls.clientSecret.value
      );

      this.companyService.createCompany(request).then(
        (company: Company) => {
          this.loading = false;
          this.config.data.onCompanyCreated(company);
          this.ref.close(company);
        },
        (error) => {
          this.loading = false;
          this.formError = error.error.message ?? error.error;
        }
      );
    } else {
      const request = new UpdateCompanyRequest(
        this.config.data.company.id,
        this.form.controls.name.value,
      );

      this.companyService.updateCompany( request).then(
        (company: Company) => {
          this.loading = false;
          this.config.data.onCompanyUpdated(company);
          this.ref.close(company);
        },
        (error) => {
          this.loading = false;
          this.formError = error.error.message ?? error.error;
        }
      );
    }
  }

  getError(formControlName: string): string {
    //in polish
    if (this.form.controls[formControlName].hasError('required')) {
      return 'To pole jest wymagane';
    }

    if (this.form.controls[formControlName].hasError('minlength')) {
      return 'To pole musi mieć co najmniej 3 znaki';
    }

    if (this.form.controls[formControlName].hasError('pattern')) {
      return 'Niepoprawny format';
    }

    return '';
  }

  onChangeNameFromBdo(event: CheckboxChangeEvent) {
    if (event.checked) {
      this.form.controls.name.removeValidators([Validators.required, Validators.minLength(AccountValidators.MIN_CREDENTIALS_NAME_LENGTH)]);
      this.form.controls.name.disable();

      this.form.controls.clientId.addValidators([Validators.required]);
      this.form.controls.clientSecret.addValidators([Validators.required]);
      this.form.controls.nip.removeValidators([Validators.required]);
      this.form.controls.nip.disable();
    } else {
      this.form.controls.name.setValidators([Validators.required, Validators.minLength(AccountValidators.MIN_CREDENTIALS_NAME_LENGTH)]);

      this.form.controls.clientId.removeValidators([Validators.required]);
      this.form.controls.clientSecret.removeValidators([Validators.required]);
      this.form.controls.nip.addValidators([Validators.required]);

      this.form.controls.nip.enable();
      this.form.controls.name.enable();
    }

    this.form.controls.name.updateValueAndValidity();
    this.form.controls.clientId.updateValueAndValidity();
    this.form.controls.clientSecret.updateValueAndValidity();
  }

  isRequired(controlName: string): boolean {
    return this.form.get(controlName)?.hasValidator(Validators.required);
  }

  isAuto(): boolean{
    return this.form.controls.nameFromBdo.value;
  }

}

export class CreateCompanyDialogData {
  constructor(
    readonly mode: 'create' | 'edit' = 'create',
    readonly onCompanyCreated?: (company: Company) => void,
    readonly onCompanyUpdated?: (company: Company) => void,
    readonly company?: Company,
  ) { }
}
