import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, isDevMode, APP_INITIALIZER, NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import '@angular/common/locales/global/pl'
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { DateInterceptor } from './services/bdoservice/common/interceptors/date-interceptor';
import { DashboardModule } from './dashboard/dashboard.module';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { companyContextReducer, localStorageMetaReducer } from './common/ui/company-context-select/company-context.reducer';
import { ApiModule as PackagingApiModule, Configuration as PackagingConfiguration, ConfigurationParameters as PackagingConfigurationParameters } from '@mobiletract/mobiletract-packaging-angular-api';
import { ApiModule as WasteRecordApiModule, Configuration as WasteRecordConfiguration, ConfigurationParameters as WasteRecordConfigurationParameters } from '@mobiletract/wasterecord-service-angular-client';
import { ApiModule as AuthServiceApiModule, Configuration as AuthServiceConfiguration, ConfigurationParameters as AuthServiceConfigurationParameters } from '@mobiletract/auth-service-angular-client';
import { ApiModule as ReportingServiceApiModule, Configuration as ReportingServiceConfiguration, ConfigurationParameters as ReportingServiceConfigurationParameters } from '@mobiletract/reporting-service-angular-client';

import { HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { version } from '../../package.json';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Error404Module } from './error-page/error-page.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?version=' + version);
}


function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloakUrl,
        realm: environment.realm,
        clientId: environment.clientId
      },
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false
      },
      shouldAddToken: (request) => {
        if (request.url.endsWith("bdomobile.json")) {
          return false;
        }
        return true;
      }
    });
}

export function packagingApiConfigFactory(): PackagingConfiguration {
  const params: PackagingConfigurationParameters = {
    basePath: `${environment.apiUrl}pms`,
  };
  return new PackagingConfiguration(params);
}

export function wasteRecordApiConfigFactory(): WasteRecordConfiguration {
  const params: WasteRecordConfigurationParameters = {
    basePath: `${environment.apiUrl}wasterecord`,
  };
  return new WasteRecordConfiguration(params);
}

export function authApiConfigFactory(): AuthServiceConfiguration {
  const params: AuthServiceConfigurationParameters = {
    basePath: `${environment.apiUrl}uaa`,
  };
  return new AuthServiceConfiguration(params);
}

export function reportingApiConfigFactory(): ReportingServiceConfiguration {
  const params: ReportingServiceConfigurationParameters = {
    basePath: `${environment.apiUrl}rs`,
  };
  return new ReportingServiceConfiguration(params);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  bootstrap: [AppComponent], imports: [BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    KeycloakAngularModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    GoogleTagManagerModule,
    MessagesModule,
    ToastModule,
    DashboardModule,
    Error404Module,
    StoreModule.forRoot({
      companyContext: companyContextReducer,
    }, {
      metaReducers: [
        localStorageMetaReducer,
      ]
    }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    PackagingApiModule.forRoot(packagingApiConfigFactory),
    WasteRecordApiModule.forRoot(wasteRecordApiConfigFactory),
    AuthServiceApiModule.forRoot(authApiConfigFactory),
    ReportingServiceApiModule.forRoot(reportingApiConfigFactory),
  ], 
  providers: [
      {
        provide: LOCALE_ID,
        useValue: 'pl-PL'
      },
      {
        provide: APP_INITIALIZER,
        useFactory: initializeKeycloak,
        multi: true,
        deps: [KeycloakService]
      },
      {
        provide: 'googleTagManagerId',
        useValue: environment.googleTagManagerId
      },
      { provide: 'googleTagManagerPreview', useValue: environment.googleTagManagerPreview },
      { provide: 'googleTagManagerAuth', useValue: environment.googleTagManagerAuth },
      { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
        }),
      }, {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => { },
        deps: [Sentry.TraceService],
        multi: true,
      },
      provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule { }
